import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import AutoComplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import Alert from "@mui/material/Alert";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  DollarType,
  BudgetID,
  yearlyUpdateCategories,
} from "../../../../app/models";
import {
  setDefinitionHelpers,
  selectPrintScreenOpen,
} from '../../../Common/stateSlices/appStateSlice';
import {
  setProgressReportRowName,
  addProgressItemsLine,
  selectProgressReportItems,
  setProgressReportItemValue,
  removeProgressReportItemsForYear,
  selectProgressReportItemValue,
  selectProgressReportItemsCategorySubTotal,
  selectProgressReportItemName,
  selectProgressReportCarryForwardSubTotal,
  selectProgressReportItemsCategoryTotal,
} from '../../../Common/stateSlices/budgetStateSlice';
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { formatDollarValues, handleDollarInput } from "../../../Common/helpers";
import { TableCellBorderLR, 
  TableCellBorderL, 
  TableCellBorderR,
  TableRowShaded 
  }from '../../../Common/ThemedMuiComponents';

function createBudgetCategory(
  id: string = "",
  name: string = "",
  groupName: string = "",
  maxItems: number = 10
) {
  return {
    id,
    name,
    groupName,
    maxItems
  };
}


function ItemRow(props: {
  nameEditable: boolean; 
  rowIndex: number; 
  category: string; 
  row: ReturnType<typeof createBudgetCategory>; 
  insideRow: {reqdLine?: boolean, lineName?: string, carryforward?: boolean};
  progressReportId: number;
  disabled: boolean;
  locked: boolean;
}){
  const { row, rowIndex, locked, insideRow, progressReportId, disabled} = props;
  const dispatch = useAppDispatch();
  const progressReportItemName = useAppSelector(selectProgressReportItemName);
  const progressReportItemValue = useAppSelector(selectProgressReportItemValue);
  const printScreenOpen = useAppSelector(selectPrintScreenOpen);
  const deleteRow = (index: number) => {
    dispatch(removeProgressReportItemsForYear({progressReportId, category: row.id, lineIndex: index }));
  };

  return(
    <TableRow
      sx={{
        "& > *": { borderBottom: "unset" },
      }}>
{/* delete icon button or required icon */}
      <TableCell sx={{ minWidth: "4ch", padding: 0 }}>
        {insideRow.reqdLine || disabled || locked || printScreenOpen  ? (
          null
        ) : (
          <Tooltip title="Delete row">
            <IconButton onClick={() => deleteRow(rowIndex)}>
              <RemoveCircleOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      <TableCell component="th" scope="row" sx={{ minWidth: "35ch", paddingLeft: "20px" }}>
        {insideRow.reqdLine || locked || disabled || printScreenOpen ? (
          <Typography>{insideRow.lineName}</Typography>
        ) : (
// input box for enter expenses
          <FormControl fullWidth size="small">
            {(row.id === 'programExpense' || row.id === 'capitalExpense')
              ? <AutoComplete
                id="outlined-adornment-amount"
                spellCheck={true}
                freeSolo
                placeholder={`Enter ${row.name}...`}
                options={[]}
                renderInput={(params) => <TextField {...params} label={row.groupName} />}
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>, newInputVal) => {
                  dispatch(setProgressReportRowName({progressReportId, category: row.id, lineIndex: rowIndex, lineName: newInputVal }))
                }}
                  value={progressReportItemName( progressReportId, row.id, rowIndex)}
                disabled = {disabled || locked }
              />
              :
              <OutlinedInput
// Might use in the future
                id="outlined-adornment-amount"
                spellCheck={true}
                placeholder={`Enter ${row.name}...`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(setProgressReportRowName({progressReportId, category: row.id, lineIndex: rowIndex, lineName: event.target.value }))
                }}
                  value={progressReportItemName(progressReportId, row.id, rowIndex)}
                disabled = {disabled}
              />
            }
          </FormControl>
        )}
      </TableCell>
      {yearlyUpdateCategories.map((el, index) => {
        return(
          <React.Fragment key={el.categoryId}>
{/* left side of new amount */}
            <TableCellBorderL align="right" sx={{padding: 1}}>             
              {
                row.id !== BudgetID.revenue && el.categoryId !== "current" && !printScreenOpen
                  ?<FormControl size="small" sx={{ width: "17ch"}}>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        sx={{ input: { textAlign: 'right' }}}
                        placeholder={"--"}
                        startAdornment={
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        }
                        value={(progressReportItemValue(progressReportId, row.id, rowIndex, el.categoryId, DollarType.TDE) || "").toLocaleString()}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setProgressReportItemValue({ progressReportId, category: row.id, index: rowIndex, budgetType: el.categoryId , amount: handleDollarInput(event.target.value), typeDollars: DollarType.TDE }));
                        }}
                        disabled= {disabled}
                      />

                    </FormControl>
                  :<Typography sx={{minWidth: "12ch", textAlign: "center"}}>
                    {
                      formatDollarValues(progressReportItemValue(progressReportId, row.id, rowIndex, el.categoryId, DollarType.TDE ))
                    }
                   </Typography>

              }
            </TableCellBorderL>

{/* right side of new amount */}
            <TableCellBorderR align="right" sx={{  padding: 1 }}>
              {el.categoryId === "current"
                ? <Typography sx={{ minWidth: "12ch", textAlign: "center" }}>
                  {
                    formatDollarValues(progressReportItemValue(progressReportId, row.id, rowIndex, el.categoryId, DollarType.Other ))
                  }
                </Typography>
                : printScreenOpen
                  ? <Typography sx={{ minWidth: "12ch", textAlign: "center" }}>
                    {
                      (formatDollarValues(progressReportItemValue(progressReportId, row.id, rowIndex, el.categoryId, DollarType.Other)))
                    }
                  </Typography>
                  :( (row.id === BudgetID.revenue && rowIndex > 0) || row.id !== BudgetID.revenue) &&
                  <FormControl size="small" sx={{ width: "17ch" }}>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    sx={{input: {textAlign: 'right'}}}
                    disabled={disabled}
                    placeholder={"--"}
                    startAdornment={
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    }
                    value={(progressReportItemValue(progressReportId, row.id, rowIndex, el.categoryId, DollarType.Other) || "").toLocaleString()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setProgressReportItemValue({ progressReportId, category: row.id, index: rowIndex, budgetType: el.categoryId,  amount: handleDollarInput(event.target.value), typeDollars: DollarType.Other }));
                    }}
                  />
                  </FormControl>
                  
                
              }
            </TableCellBorderR>
          </React.Fragment>
        )})}
    </TableRow>
  )
}

export function ProgressReportCategoryRow(props: {
  row: ReturnType<typeof createBudgetCategory>
  progressReportId: number,
  disabled: boolean
}) {
  const { row, progressReportId, disabled} = props;
  const [open, setOpen] = React.useState(true);
  const progressReportItems = useAppSelector(selectProgressReportItems);
  const progressReportItemsCategorySubTotal = useAppSelector(selectProgressReportItemsCategorySubTotal)
  const progressReportCarryForwardSubTotal = useAppSelector(selectProgressReportCarryForwardSubTotal)
  const printScreenOpen = useAppSelector(selectPrintScreenOpen);
  const progressReportItemsCategoryTotal = useAppSelector(selectProgressReportItemsCategoryTotal)

  const dispatch = useAppDispatch();
  const addRow = () => {
    dispatch(addProgressItemsLine({progressReportId, category: row.id}))
  };
  const handleDefClickOpen = (helperKey: string) => {
    dispatch(setDefinitionHelpers({ visible: true, key: helperKey }))
  }

  function CreateDropDownRows(progressReportId, revenueOrExpenseType) {
    const dropDownRowData = progressReportItems(progressReportId)[revenueOrExpenseType]
    const returnElement = dropDownRowData.map((insideRow, rowIndex) => {
      return <React.Fragment key={rowIndex}>
        <ItemRow
          category={row.id}
          nameEditable={true}
          rowIndex={rowIndex}
          row={row}
          insideRow={insideRow}
          progressReportId={progressReportId}
          disabled={disabled}
          locked = {insideRow.locked}
        />
      </React.Fragment>
  })
    return returnElement
  }

  return (
    <React.Fragment>
      <TableRow 
        sx={{
           "& > *": { borderBottom: "unset" },
          backgroundColor: "rgba(117, 106, 78,.1)",
          borderTop: "2px solid rgba(0,0,0,.3)",
          borderBottom: "2px solid rgba(0,0,0,.3)"
      }}>
{/* collapse icon or uncollapse icon in the beginning */}
        <TableCell sx={{ width: "5ch", padding: 0 }}>
          {!printScreenOpen && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
{/* Header for Total Amount*/}
{/* REVENUE, DIRECT EXPENSES AND INDIRECT EXPENSES */}
        <TableCell
          component="th"
          scope="row"
          sx={{
            width: "38ch",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
          >
            {row.groupName}
            { !printScreenOpen && <IconButton
              size="small"
              sx={{ position: 'relative', top: '-8px', padding: 0 }}
              id="test"
              onClick={() => handleDefClickOpen(row.id)}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
            }
          </Typography>
        </TableCell>
        {yearlyUpdateCategories.map((el, i) => {
          return(
          <React.Fragment key={i}>
            <TableCellBorderLR sx={{fontWeight: "bold", fontSize: "1rem"}} colSpan={2} align="center">
              {
                formatDollarValues( progressReportItemsCategoryTotal(progressReportId, row.id, el.categoryId ))
              }
            </TableCellBorderLR>
          </React.Fragment>
          )
        })}
      </TableRow>
{/* Item rows */}
      {open && 
        CreateDropDownRows(progressReportId, row.id)
      }
      {open &&  ( 
      <>
        {!printScreenOpen && <TableRow sx={{
          borderBottom: "1px solid rgba(0,0,0,.1)"
        }}>
          <TableCell sx={{ borderBottom: "0px solid black" }} />
{/* ADD new Expense Button Or Alert for the Max Line  */}
          <TableCell sx={{ borderBottom: "0px solid black", paddingY: "5px"}} >
            {!printScreenOpen && progressReportItems(progressReportId)[row.id].length > row.maxItems - 1 ? (
              <>
                {true ? (
                  <Alert icon={<HelpOutlineIcon fontSize="inherit" />} severity="warning" variant="outlined" sx={{ marginBottom: 1 }}>
                    Bundle all other {row.groupName} in single line item.
                  </Alert>
                ) : null}
                <Alert severity="info" >Max number of rows allowed.</Alert>
              </>
            ) : 
                !disabled 
                  ?<Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={addRow}
                  >
                Add {row.name}
              </Button>
              : null
            }
          </TableCell>
{/* Blank spacing after Add button */}
            {yearlyUpdateCategories.map((el, index) => {
              return (
                <React.Fragment key={el.categoryId}>
                  <>
                    <TableCellBorderL align="right" sx={{ padding: 1 }} colSpan={2}>
                    </TableCellBorderL>
                  </>
                </React.Fragment>
              );
            })}
        </TableRow>}
{/* CarryForward Row */}
        { row.id === BudgetID.revenue  
          ?<TableRowShaded>
            <TableCell sx={{ minWidth: "4ch", padding: 0 }}></TableCell>
            <TableCell component="th" scope="row" sx={{ padding: "8px" }}>
              <Typography >Carryforward</Typography>
            </TableCell>
            {yearlyUpdateCategories.map((el, index) => {
              return (
                <React.Fragment key={el.categoryId}>
                  {/* left side of new amount */}
                  <TableCellBorderL align="right" sx={{ paddingY: "8px" }}>
                    <Typography sx={{ minWidth: "12ch", textAlign: "right" }}>
                      {
                        formatDollarValues(
                          progressReportCarryForwardSubTotal(progressReportId, el.categoryId, DollarType.TDE)
                        )
                      }
                    </Typography>
                  </TableCellBorderL>
                  {/* right side of new amount */}
                  <TableCellBorderR align="right" sx={{ paddingY: "8px" }}>

                    <Typography sx={{ minWidth: "12ch", textAlign: "right" }}>
                      {
                        formatDollarValues(
                        progressReportCarryForwardSubTotal(progressReportId, el.categoryId, DollarType.Other)
                        )
                      }
                    </Typography>

                  </TableCellBorderR>
                </React.Fragment>
              )
            })}
          </TableRowShaded>
          : null
        }
{/* SubTotals Row */}
        <TableRowShaded>
          <TableCell />
          <TableCell sx={{ padding: "8px" }} >
            <Typography>Sub-Totals for {row.name}</Typography>
          </TableCell>
          {yearlyUpdateCategories.map((el, i) => {
            return (
              <React.Fragment key={i}>
                <TableCellBorderL sx={{ paddingY: "8px"}}  align="right">
                  <Typography>
                    {
                      formatDollarValues(
                        progressReportItemsCategorySubTotal(progressReportId, row.id, el.categoryId, DollarType.TDE )
                      )
                    }
                  </Typography>
                </TableCellBorderL>
                <TableCellBorderR sx={{ paddingY: "8px" }}  align="right">
                  <Typography >
                  {
                      formatDollarValues(
                        progressReportItemsCategorySubTotal(progressReportId, row.id, el.categoryId, DollarType.Other )
                      )
                    }
                  </Typography>
                </TableCellBorderR>
              </React.Fragment>
            )
          })}      
        </TableRowShaded>
{/* Totals Row */}
        <TableRowShaded>
          <TableCell />
          <TableCell sx={{ padding: "8px" }} >
            <Typography>Totals for {row.name}</Typography>
          </TableCell>
          {yearlyUpdateCategories.map((el, i) => {
            return (
              <React.Fragment key={i}>
                <TableCellBorderLR sx={{ padding: "8px" }} colSpan={2}  align="center">
                  <Typography >
                    {
                      formatDollarValues(
                        progressReportItemsCategoryTotal(progressReportId, row.id, el.categoryId )
                      ) 
                    }
                  </Typography>
                </TableCellBorderLR>
              </React.Fragment>
            )
          })}
        </TableRowShaded>
      </>
      )}
      { row.id === BudgetID.revenue &&
        <TableRow >
          <TableCell sx={{padding: "5px"}}/>
        </TableRow> 
      }
    </React.Fragment>
  );
}

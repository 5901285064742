import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Alert } from '@mui/material';

import { useAppSelector } from "../../app/hooks";
import {
  DollarType,
  BudgetID, 
  yearlyUpdateCategories, //list categoryId for budgetItemsCategoryTotal
  yearlyUpdateId
} from "../../app/models";

import { 
  selectProgressReportItemsCategorySubTotal,
  selectProgressReportItemsCategoryTotal,
  selectCurrentProgressReport,
  selectProgressReportItemsCategoryNetSubTotal,
  selectProgressReportItemsCategoryNetTotal,
  selectProgressReportItemsExpensesTotal,
  selectProgressReportItemsExpensesSubTotal,
  selectProgressReportCarryForwardTotal,
  selectProgressReportItemValue

} 
from "../Common/stateSlices/budgetStateSlice";

import { formatDollarValues } from "../Common/helpers";
import { TableRowCat } from "../Common/ThemedMuiComponents";

const currentAndFutureYearlyCats = yearlyUpdateCategories
  .filter(e => e.categoryId !== yearlyUpdateId.current)

export const ProgressReportSubmit = () => {
  const progressReportId = useAppSelector(selectCurrentProgressReport);

  const progressReportItemsCategoryNetSubTotal = useAppSelector(selectProgressReportItemsCategoryNetSubTotal);
  const projectedNetDeficitExcess = progressReportItemsCategoryNetSubTotal(progressReportId, yearlyUpdateId.projected, DollarType.TDE)
  const isNetDeficitOrExcess = projectedNetDeficitExcess !== 0

    
    return (
      <Box
        component="form"
        sx={{
          // "& .MuiTextField-root": { m: 1, width: "50ch" },
          mx: "auto", 
          width: "90vw",
          
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h1" sx={{fontSize:"4ch", fontWeight: 400}}>Submit your progress report budget</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {currentAndFutureYearlyCats 
                .map((el, index) => {
                  return (
                    <TableCell key={el.categoryId}>
                      <Typography variant="h4" component="h4" align="center" color="primary" sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
                        {el.categoryName}
                      </Typography>
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            <RevenueRows/>
            <ExpenseRows />
            <NetDeficitRows/>
          </TableBody>
        </Table>
        { isNetDeficitOrExcess &&
            <Alert sx={{mt: "1ch"}} severity="warning">There is a projected surplus or deficit of Duke Endowment Funds. Please review your budget. If this is accurate, please note the reason in the narrative on the previous page or coordinate with your Endowment contact person using the <strong>Contact Us</strong> button on your progress report form.</Alert>
        }
      </Box>
    );
  }

const SubmittalColumns = [yearlyUpdateId.actual, yearlyUpdateId.projected, yearlyUpdateId.nextReport]

const RevenueRows = () => {
  const progressReportId = useAppSelector(selectCurrentProgressReport);
  const progressReportItemValue = useAppSelector(selectProgressReportItemValue)
  const progressReportItemsCategoryTotal = useAppSelector(selectProgressReportItemsCategoryTotal);
  const progressReportItemsCategorySubTotal = useAppSelector(selectProgressReportItemsCategorySubTotal);
  const progressReportCarryForwardTotal = useAppSelector(selectProgressReportCarryForwardTotal);
  
  return (
    <>
      <TableRowCat> {/*//REVENUE PRIMARY ROW */}
        <TableCell>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            Total Revenues:
          </Typography>
        </TableCell>
        {
          SubmittalColumns.map((el, index) => {
            return (
              <TableCell align="right" key={index}>
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  {
                    formatDollarValues(
                      progressReportItemsCategoryTotal(progressReportId, BudgetID.revenue, el)
                    )
                  }
                </Typography>
              </TableCell>
            )
          })
        }
        </TableRowCat>
        <TableRow>
          <TableCell><Typography variant="h6" color="primary" >The Duke Endowment</Typography></TableCell>
          {
            SubmittalColumns.map( el => {
              return ( createTableCells(
                    progressReportItemValue(progressReportId, BudgetID.revenue, 0, el, DollarType.TDE), "h6"
                  )
                )
              })
          }
        </TableRow>
        <TableRow>
          <TableCell><Typography variant="h6" color="primary" >Other Sources</Typography></TableCell>
          {
          SubmittalColumns.map( el => {
            return ( createTableCells(
                  progressReportItemsCategorySubTotal(progressReportId, BudgetID.revenue, el, DollarType.Other), "h6"
                )
            )
          })
        }
        </TableRow>
        <TableRow>
          <TableCell ><Typography variant="h6" color="primary" >Carryforward (net dollar excess)</Typography></TableCell>
          {
          SubmittalColumns.map( el => {
            return ( createTableCells(
              progressReportCarryForwardTotal(progressReportId, el), "h6"
                )
            )
          })
        }
        </TableRow>
    </>
  )
}

const ExpenseRows = () => {
  const progressReportId = useAppSelector(selectCurrentProgressReport);
  const progressReportItemsExpensesTotal = useAppSelector(selectProgressReportItemsExpensesTotal)
  const progressReportItemsExpensesSubTotal = useAppSelector(selectProgressReportItemsExpensesSubTotal)

  return (
    <>
      <TableRowCat>
        <TableCell>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            Total Expenses:
          </Typography>
        </TableCell>
          {
            SubmittalColumns.map((el, index) => {
              return (
                <TableCell align="right" key={index}>
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {
                      formatDollarValues(
                        progressReportItemsExpensesTotal(progressReportId, el)
                      )
                    }
                  </Typography>
                </TableCell>
              )
            })
          }

      </TableRowCat>
      <TableRow>
        <TableCell><Typography variant="h6" color="primary" >The Duke Endowment</Typography></TableCell>
        {
          SubmittalColumns.map( el => {
            return ( createTableCells(
                progressReportItemsExpensesSubTotal(progressReportId, el, DollarType.TDE), "h6"
                )
            )
          })
        }
      </TableRow>
      <TableRow>
          <TableCell><Typography variant="h6" color="primary" >Other Sources</Typography></TableCell>
          {
          SubmittalColumns.map( el => {
            return ( createTableCells(
                progressReportItemsExpensesSubTotal(progressReportId, el, DollarType.Other), "h6"
                )
            )
          })
        }
        </TableRow>
    </>
  )
}

const NetDeficitRows = () => {

  const progressReportId = useAppSelector(selectCurrentProgressReport);
  const progressReportItemsCategoryNetSubTotal = useAppSelector(selectProgressReportItemsCategoryNetSubTotal);
  const progressReportItemsCategoryNetTotal = useAppSelector(selectProgressReportItemsCategoryNetTotal);

  return (
    <>
    <TableRowCat>
      <TableCell>
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: "bold" }}
        >
          Net Dollar Excess (deficit):
        </Typography>
      </TableCell>
      {
          SubmittalColumns.map((el, index) => {
            return (
              <TableCell align="right" key={index}>
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  {
                    formatDollarValues(
                      progressReportItemsCategoryNetTotal(progressReportId, el)
                    )
                  }
                </Typography>
              </TableCell>
            )
          })
        }

      </TableRowCat>
      <TableRow>
        <TableCell><Typography variant="h6" color="primary" >The Duke Endowment</Typography></TableCell>
        {
            SubmittalColumns.map( el => {
              return ( createTableCells(
                progressReportItemsCategoryNetSubTotal(progressReportId, el, DollarType.TDE), "h6"
                  )
                )
              })
          }
      </TableRow>
      <TableRow>
        <TableCell><Typography variant="h6" color="primary" >Other Sources</Typography></TableCell>
        {
            SubmittalColumns.map( el => {
              return ( createTableCells(
                progressReportItemsCategoryNetSubTotal(progressReportId, el, DollarType.Other), "h6"
                  )
                )
              })
          }
      </TableRow>
  </>
  )
}


function createTableCells(dollarVal, variant, cellStyle = {}) {
  return (
    <TableCell>
      <Typography
        variant={variant}
        color="primary"
        textAlign={'end'}
        sx={cellStyle}
      >
        {formatDollarValues(dollarVal)}
      </Typography>
    </TableCell>
  )
}


